#org-member-modal .modal-wrapper {
  min-width: 1040px !important;
  min-height: 560px;
}

.org-member-content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
