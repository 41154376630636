.number-text{
    font-size: 80px;
    text-align: center;
}

.unit-text {
    text-align: right;
}

ion-card-content > ion-card-title {
    text-align: center;
}

ion-card-subtitle > ion-text {
    font-size: 15px;
    font-weight: bold;
}

:root {
	--ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;

    --ion-color-blue-light: #2D95F4;
	--ion-color-blue-light-rgb: 45,149,244;
	--ion-color-blue-light-contrast: #000000;
	--ion-color-blue-light-contrast-rgb: 0,0,0;
	--ion-color-blue-light-shade: #2883d7;
	--ion-color-blue-light-tint: #42a0f5;

    --ion-color-blue-gray: #5B8AC3;
	--ion-color-blue-gray-rgb: 91,138,195;
	--ion-color-blue-gray-contrast: #000000;
	--ion-color-blue-gray-contrast-rgb: 0,0,0;
	--ion-color-blue-gray-shade: #5079ac;
	--ion-color-blue-gray-tint: #6b96c9;

    --ion-color-green: #21BA45;
	--ion-color-green-rgb: 33,186,69;
	--ion-color-green-contrast: #ffffff;
	--ion-color-green-contrast-rgb: 255,255,255;
	--ion-color-green-shade: #1da43d;
	--ion-color-green-tint: #37c158;
    
    --ion-color-dark-green: #21BA45;
	--ion-color-dark-green-rgb: 33,186,69;
	--ion-color-dark-green-contrast: #ffffff;
	--ion-color-dark-green-contrast-rgb: 255,255,255;
	--ion-color-dark-green-shade: #1da43d;
	--ion-color-dark-green-tint: #37c158;

    --ion-color-orange: #F5B300;
	--ion-color-orange-rgb: 245,179,0;
	--ion-color-orange-contrast: #000000;
	--ion-color-orange-contrast-rgb: 0,0,0;
	--ion-color-orange-shade: #d89e00;
	--ion-color-orange-tint: #f6bb1a;

    --ion-color-red: #D0021B;
	--ion-color-red-rgb: 208,2,27;
	--ion-color-red-contrast: #ffffff;
	--ion-color-red-contrast-rgb: 255,255,255;
	--ion-color-red-shade: #b70218;
	--ion-color-red-tint: #d51b32;
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-blue-light {
	--ion-color-base: var(--ion-color-blue-light);
	--ion-color-base-rgb: var(--ion-color-blue-light-rgb);
	--ion-color-contrast: var(--ion-color-blue-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-blue-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-blue-light-shade);
	--ion-color-tint: var(--ion-color-blue-light-tint);
}

.ion-color-blue-gray {
	--ion-color-base: var(--ion-color-blue-gray);
	--ion-color-base-rgb: var(--ion-color-blue-gray-rgb);
	--ion-color-contrast: var(--ion-color-blue-gray-contrast);
	--ion-color-contrast-rgb: var(--ion-color-blue-gray-contrast-rgb);
	--ion-color-shade: var(--ion-color-blue-gray-shade);
	--ion-color-tint: var(--ion-color-blue-gray-tint);
}

.ion-color-green {
	--ion-color-base: var(--ion-color-green);
	--ion-color-base-rgb: var(--ion-color-green-rgb);
	--ion-color-contrast: var(--ion-color-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-green-shade);
	--ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-dark-green {
	--ion-color-base: var(--ion-color-dark-green);
	--ion-color-base-rgb: var(--ion-color-dark-green-rgb);
	--ion-color-contrast: var(--ion-color-dark-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-dark-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-dark-green-shade);
	--ion-color-tint: var(--ion-color-dark-green-tint);
}

.ion-color-orange {
	--ion-color-base: var(--ion-color-orange);
	--ion-color-base-rgb: var(--ion-color-orange-rgb);
	--ion-color-contrast: var(--ion-color-orange-contrast);
	--ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
	--ion-color-shade: var(--ion-color-orange-shade);
	--ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-red {
	--ion-color-base: var(--ion-color-red);
	--ion-color-base-rgb: var(--ion-color-red-rgb);
	--ion-color-contrast: var(--ion-color-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-red-shade);
	--ion-color-tint: var(--ion-color-red-tint);
}