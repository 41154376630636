.user-box {
    top: 58px;
    right: 50px;
    width: 272px;
    height: 265px;
    box-shadow: 0px 3px 6px #122D4E80;
    opacity: 1;
    text-align: center;
    z-index: 100;
    border-radius: 10px;
    position: absolute;
    background-color: #ffffff;
    
    .user-container-box {
        padding: 32px;

        .avatar {
            margin: 0 auto;
            width: 50px;
            height: 50px;
            img {
                border-radius: 50%;
            }
        }
        
        .title {
            margin: 10px;
            font-size: 17px;
            font-weight: bold;
        }
        
        .description {
            margin: 10px;
            font-size: 12px;
        }

        .logout-btn {
            margin-top: 32px;
            background-color: #ffffff;
            border: #000 solid 1px;
            padding: 10px;
            border-radius: 8px;
        }
        
        .logout-btn:hover {
            background-color: #f2f2f2;
            border: #ffffff solid 1px;
        }
    }
}
