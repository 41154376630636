ion-popover.notification-list-popover {
  --width: 300px;
  .popover-wrapper {
    .popover-content {
      .popover-viewport {
        ion-content {
        }
      }
    }
  }
}
