
#dashboard-page > ion-grid {
    padding-left: 4% !important;
    padding-right: 32% !important;
}

#dashboard-label {
    margin-left: 8px;
    font-size: 24px;
    font-weight: bold;
}

ion-card-header > ion-card-title > ion-icon {
    float: right;
    font-size: 36px;
}

#secondary-row-1 #secondary-row-3{
    margin-top: 16px;
}

.secondary-labels {
    margin-left: 8px;
}

/* #main-row-2 ion-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    clip-path: circle(50%);
} */

.badge {
    z-index: 1;
    display: inline-block;
    padding: 3px 8px;
    min-width: 10px;
    border-radius: 10px;
    vertical-align: baseline;
    text-align: center;
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    top: -12px;
    right: -12px;
    position: absolute;
}

.badge.badge-assertive {
    background-color: #ef4e3a;
    color: #fff;
}