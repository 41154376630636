.teams-info-header-content {
  background-color: #f7fbff;
  padding-left: 4% !important;
  padding-right: 4% !important;
  min-width: 992px;
}

.teams-info-header-content > ion-row {
  margin-top: 16px;
  margin-bottom: 16px;
}

.teams-info-back-button {
  --padding-start: 0px;
  --padding-end: 0px;
  --icon-font-size: 24px;
  font-size: 16px;
  color: black;
}

.team-img-full {
  width: 96px;
  height: 96px;
}

.team-info-header-team-name-label {
  font-size: 24px;
  font-weight: bold;
}

.team-info-header-content > ion-col > ion-row:nth-of-type(2) {
  margin-top: 16px;
}

.team-info-header-team-info {
  font-size: 16px;
  font-weight: bold;
}

.team-info-edit-team-info {
  float: right;
  --background: #1b4170;
}

.icon-24 {
  font-size: 24px;
}

.team-info-content {
  padding-left: 4%;
  padding-right: 4%;
  min-width: 992px;
}

.teams-info-teams-search-col {
  display: flex;
}

.teams-info-teams-search-button {
  align-self: center;
}

.teams-info-teams-search-bar {
  width: 66%;
}

.teams-info-providers-list {
  min-height: 48vh;
}

.link-item {
  text-decoration: none;
}

.member-role {
  display: flex;
  align-items: center;
  .member-icon {
    height: 25px;
    width: 25px;
    margin-right: 10px;
  }

  .badge-icon {
    height: 25px;
    width: 25px;
    margin: auto;
    margin-right: 10px;
    display: inline-flex;
  }
}
