.unread-icon {
    font-size: 248px;
}

.unread-row {
    margin: 2% 8%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-font {
    font-size: 32px;
}

.login-title{
    font-weight: bold;
    font-size: 32px;
    margin: 1% 8%;
}

.reset-subheader {
    font-size: 24px;
    margin: 1% 8%;
}

.reset-button {
    margin: 2% 8%;
    width: 100%;
}

.reset-text {
    font-size: 16px;
    margin: 1% 8%;
}