#job-assign-modal .modal-wrapper {
  height: 100vh;
  width: 100vw;
}

.job-info-header-conten-modal {
  background-color: aliceblue;
  ion-button.close-icon-button {
    --background: #1b4171;
  }
}

.header-content-modal {
  padding-left: 30px;
}

.header-sub-content-modal {
  padding-left: 50px;
  padding-bottom: 12px;
}

.jobs-search-button-modal {
  align-self: center;
  width: 7%;
}

.modal-contanier {
  margin: 0 45px;
  padding: 0;
}

.search-container {
  padding-bottom: 12px;
}

.search-field {
  width: 50%;
}

ion-row.member-list-header {
  padding: 10px 0;
  background-color: #f7fbff;
}

ion-row.member-list-header,
ion-row.render-team,
ion-row.render-member {
  ion-item {
    --inner-padding-start: 10px;
    --padding-start: 0px;
  }

  ion-col {
    display: flex;
    align-items: center;
    word-break: break-word;

    &.name {
      min-width: 200px;
      ion-label {
        white-space: break-spaces;
      }
    }
    &.phone-number,
    &.rating {
      max-width: 100px;
    }
    &.email {
      max-width: 200px;
    }
    &.member {
      max-width: 100px;
    }
  }
}
