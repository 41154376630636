.org-box {
    display: flex;
    align-items: center;
    .org-img {
        width: 38px;
        height: 38px;
        border-radius: 50%;
    }
    
    .org-name {
        font-size: 14px;
        margin-left: 8px;
    }
}
