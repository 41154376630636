ion-toolbar > ion-buttons > ion-icon {
  font-size: 24px;
  vertical-align: middle;
}

ion-toolbar > ion-buttons {
  margin-left: 8px;
  margin-right: 8px;
}

ion-toolbar > ion-buttons > ion-label {
  margin-left: 4px;
  vertical-align: middle;
}

:root {
  --ion-color-toolbar-blue: #1b4170;
  --ion-color-toolbar-blue-rgb: 27, 65, 112;
  --ion-color-toolbar-blue-contrast: #ffffff;
  --ion-color-toolbar-blue-contrast-rgb: 255, 255, 255;
  --ion-color-toolbar-blue-shade: #183963;
  --ion-color-toolbar-blue-tint: #32547e;
}

.change-lang {
  cursor: pointer;
}

.ion-color-toolbar-blue {
  --ion-color-base: var(--ion-color-toolbar-blue);
  --ion-color-base-rgb: var(--ion-color-toolbar-blue-rgb);
  --ion-color-contrast: var(--ion-color-toolbar-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toolbar-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-toolbar-blue-shade);
  --ion-color-tint: var(--ion-color-toolbar-blue-tint);
}

.selected-lang {
  text-decoration: underline;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.split-pane-menu-button {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .split-pane-menu-button {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .ion-menu-button-ion-button {
    display: none;
  }
}

.split-pane-menu-button:hover {
  background-color: #e0e0e01a;
}
