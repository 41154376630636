.tab-create-teams {
  width: 100%;
  min-height: 64vh;
}

.tab-create-teams-label {
  justify-content: left;
  vertical-align: middle;
  font-size: 24px;
  display: table-cell;
}

.table-create-row-1-col-1 {
  display: table;
}

.grid-create-teams {
  margin-inline: inherit;
  margin-left: 16%;
  margin-right: 16%;
}

.add-members-button {
  float: right;
  vertical-align: middle;
}

.input {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: #eeeeee;
  font-size: 13px;
  margin-top: 8px;
}

.add-teams-image-button {
  position: absolute;
  width: 156px;
  height: 156px;
  --background: #ffffff;
  right: 0px;
}

.add-teams-image-button > div > ion-icon {
  color: #000000;
  opacity: 0.6;
  min-height: 48px;
  min-width: 48px;
}

.add-teams-preview-image {
  width: 100%;
  height: 100%;
}

.create-team-input-rows {
  margin-top: 8px;
  margin-bottom: 8px;
}

.create-teams-add-members-box {
  height: 512px;
  max-height: 33vh;
  overflow-y: hidden;
}

.create-teams-add-members-placeholder {
  --background: #eeeeee;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-teams-add-members-placeholder
  > ion-grid
  > ion-row
  > ion-col
  > ion-icon {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
  font-size: 32px;
}

.create-teams-add-members-placeholder > ion-grid > ion-row > ion-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-teams-add-members-placeholder
  > ion-grid
  > ion-row
  > ion-col
  > ion-text {
  text-align: center;
  font-size: 14px;
}

.text-area-center {
  text-align: center;
}

.create-teams-add-member-placeholder-grid {
  width: 40%;
}

.create-team-member-list-item {
  --padding-start: 0px;
}

.text-align-center {
  text-align: center;
}

.tab-create-teams-create-team-button {
  float: right;
}

.tab-create-teams-search-member-modal {
  --padding-start: 4%;
  --padding-end: 4%;
  --padding-top: 2%;
  --padding-bottom: 2%;
}

.search-members-div {
  display: flex;
}

.search-members-searchbar {
  padding-left: 0px !important;
  width: 100%;
}

/* .search-members-search-button {
    align-self: center;
} */

.search-member-list-avatar {
  width: 24px;
  height: 24px;
}

#create-team-search-modal-1 .modal-wrapper {
  min-width: 1040px !important;
}

#create-team-search-modal-2 .modal-wrapper {
  min-width: 1040px !important;
}

#create-team-alert .modal-wrapper {
  width: 256px !important;
  height: 48px !important;
}

.checkmark-icon-modal {
  color: #5b8ac3;
  font-size: 32px;
}

.search-member-list {
  max-height: 72%;
  overflow-y: auto;
}

.search-member-add-member {
  display: flex;
  justify-content: center;
}

.create-team-alert-div {
  display: inline;
}

.create-team-alert-div * {
  vertical-align: middle;
}
