.login-box {
    background: #FFFFFF;
    background: white;
    margin: auto;
    width: 50%;
    border: 3px solid black;
    border-radius: 10px;
    margin-top: 24vh;
    display: block;
}

.login-page-background {
    --background: #75A5FF !important;
}

.login-field-headers {
    margin-top: 4% !important;
    margin: 0 8%;
}

.login-fields {
    margin: 0 8%;
    border-radius: 4px;
    border-style: solid;
    border-color: black;
    border-width: 2px;
}

.text-red {
    color: red;
}