.tab-providers-list {
    width: 100%;
    min-height: 56vh;
}
.tab-providers-list-search-bar {
    width: 100%;
}

.tab-providers-search-col {
    display: flex;
}

.tab-providers-search-button {
    align-self: center;
}

.provider-list-header {
    background-color: #AED1FC;
}

.provider-list-header > ion-col {
    margin: 8px 8px;
}

.provider-list {
    min-width: 1400px;
}

.provider-list > ion-row {
    border-width: 1px;
    border-color: #EEEEEE;
    border-style: solid;
}

.provider-list > ion-row > ion-col{
    margin-left: 8px;
    margin-right: 8px;
}

.margin-left-4 {
    margin-left: 4px;
}

.team-item {
    margin-left: 0.5em;
    color: #000000;
}
