.tab-teams-list {
    width: 100%;
    min-height: 56vh;
}
.tab-teams-list-search-bar {
    width: 66%;
}

.tab-teams-search-col {
    display: flex;
}

.tab-teams-search-button {
    align-self: center;
}

.teams-list-header {
    background-color: #AED1FC;
}

.teams-list-header > ion-col {
    margin: 8px 0px;
    padding: 0px 8px;
}

.teams-list-header > ion-col > ion-label {
    font-weight: bold;
}

.teams-list > ion-row {
    border-width: 1px;
    border-color: #EEEEEE;
    border-style: solid;
}

.teams-list > ion-row > ion-col{
    padding-left: 8px;
    padding-right: 8px;
}

.teams-list-team-lead-elems{
    display: inline-block;
    vertical-align: middle;
}

.padding-right-8 {
    padding-right: 8px;
}

.team-image-in-list {
    width: 32px !important;
    height: 32px !important;
    display: inline-block;
    vertical-align: middle;
}

.display-flex {
    display: flex;
}

.align-self-center {
    align-self: center;
}

.table-row {
    color: black;
    border: 1px solid #eeeeee;
    border-top: none;
}

.team-item {
    margin-left: 0.5em;
    color: #000000;
}
