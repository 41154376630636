.job-info-content-grid {
  padding-left: 4%;
  padding-right: 4%;
}

.assign-job-button {
  float: right;
  width: var(--dropdown-box-width);
  --background: #1b4171;
  color: white;
  border-radius: 4px;
}
.margin-top-16 {
  margin-top: 16px;
}

.float-right {
  float: right;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-8 {
  margin-top: 8px;
}
.color-dark-blue {
  color: #1b4171;
}

.font-weight-bold {
  font-weight: bold;
}

.select-text {
  display: none !important;
}

.assign-job-dropdown {
  display: block !important;
  padding-left: 0px !important;
  opacity: 1 !important;
}

.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1 !important;
}

ion-select::part(icon) {
  opacity: 1;
}

.assign-job-dropdown .radio .radio-icon {
  display: none;
}

.item-select-disabled {
  --background: #dbdbdb;
  opacity: 1 !important;
}

.select-icon {
  opacity: 1 !important;
}

.job-info-header-content {
  background-color: #f7fbff;
  padding-left: 4% !important;
  padding-right: 4% !important;
  // min-width: 1400px;
  min-width: 425px;
}

.job-info-sub-headers {
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.date-text-number {
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  background-color: #5b8ac3;
  padding: 14px;
  margin-right: 14px;
}

.date-time-text-number {
  color: #1b4171;
  font-weight: bold;
  font-size: 18px;
}

.time-ragne-container {
  padding-top: 32px;
}

.time-container {
  padding-top: 16px;
}

.start-time-number {
  padding-right: 16px;
}

.tap-segment {
  justify-content: left;
  /* border-bottom-style: solid;
    border-bottom-color: #1B4170; */
}

.select-interface-option > ion-radio {
  /* visibility */
  width: 0px;
  height: 0px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  --border-color: none;
  --border-width: 0px;
  --color: none;
  --color-checked: none;
}

.provider-row {
  padding: 25px;
  border: 1px solid #eeeeee;
  margin-top: 16px;
  margin-left: -6px;
}

.text-area-comment {
  border: 1px solid #eeeeee;
  margin-top: 0;
}

.list-scorll {
  max-height: 500px;
  overflow: scroll;
}

.avatar-provider {
  width: 32px !important;
  margin-top: -16px;
  margin-bottom: -13px;
  margin-right: 5px;
  height: 32px !important;
  display: inline-block;
  vertical-align: middle;
}

.state-tag-provider {
  margin-top: -6px;
}
/* .sc-ion-popover-md-h {
    --width: calc(--dropdown-box-width);
} */
