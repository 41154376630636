.order-row {
  width: 100%;
  min-width: 400px;
}

.approve-btn {
  bottom: 4px;
  position: absolute;
}

.job-info-content-grid {
  padding-bottom: 4%;

  ion-col.edit-column {
    display: flex;
    ion-item {
      cursor: pointer;

      ion-icon {
        margin-inline-end: 12px;
        font-size: 32px;
      }
    }
  }
}
