.team-page-label {
  font-size: 24px;
  font-weight: bold;
}

.teams-header-content {
  background-color: #f7fbff;
  padding-left: 4% !important;
  padding-right: 32% !important;
}

.teams-page-content {
  padding-left: 4% !important;
  padding-right: 4% !important;
}

.teams-tab-bar {
  border-top: none;
  border-bottom-style: solid;
  justify-content: left;
}

.teams-tab-bar ion-tab-button > ion-text {
  font-size: 18px;
}

.tab-teams-segment {
  justify-content: left;
  border-bottom-style: solid;
  border-bottom-color: #1b4170;
}

.tab-teams-segment > ion-segment-button {
  padding-left: 0px;
  padding-right: 0px;
  min-width: auto !important;
  flex: none;
}

/* #teams-page > ion-grid > ion-row > ion-col > ion-tab-bar > ion-tab-button {
    --padding-start: 0px;
} */

.tab-item {
  text-decoration: none;
  ion-segment-button {
    &.create-team-button,
    &.provider-button,
    &.team-button {
      font-size: 16px;
    }
  }
}
