.report-edit-page {
  ion-grid.report-edit-nav-grid {
    background-color: #f7fbff;
    padding-left: 4% !important;
    padding-right: 4% !important;
    min-width: 1400px;

    ion-row.return-row {
    }
  }

  ion-grid.report-edit-header-grid {
    ion-label.report-edit-page-label {
      display: flex;
      align-items: flex-end;
      p {
        display: inline-block;
        color: #1b4171;
      }
    }
  }

  ion-grid.report-edit-page-content {
    overflow-y: auto;
    height: calc(100% - 155px);

    ion-list.answer-list {
      ion-item.answer-item {
        display: flex;
        align-items: flex-start;
        --highlight-background: none;

        div[slot='start'] {
          // margin-top: 2px;
          margin-bottom: auto;
        }

        div[slot='start'] + div {
          width: 100%;
        }

        ion-radio-group {
          display: flex;
        }

        &.textarea,
        &.string,
        &.number,
        &.select,
        &.searchable_select {
          ion-textarea,
          ion-input,
          ion-select {
            border: solid 1px #cacaca;
            border-radius: 5px;
            --padding-start: 5px;
            --padding-end: 5px;
            margin-top: 10px;
          }
        }

        .image-input-wrapper {
          .image-input {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            // margin-top: 10px;
            border: dashed 1px #cacaca;
            border-radius: 5px;
            --padding-start: 5px;
            --padding-end: 5px;
            cursor: pointer;
            color: #acacac;
            ion-icon {
              font-size: 36px;
            }
          }

          ion-grid.attachments-grid {
            --ion-grid-column-padding: 10px;
            ion-icon.close-icon {
              color: #acacac;
              position: absolute;
              top: 0;
              right: 0;

              height: 30px;
              width: 30px;
            }

            ion-img {
              height: 200px;
              object-fit: contain;
              // img {
              //   max-height: 200px;
              // }
            }
          }
        }
      }
    }

    ion-row.save-button-row {
      ion-col {
        display: flex;
        ion-button {
          margin-left: auto;
        }
      }
    }

    &.step-2 {
      .drag-drop-zone {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background-color: #f7fbff;
        border: dashed 1px #cacaca;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        overflow-y: auto;
        overflow-x: hidden;
        ion-icon {
          font-size: 50px;
          color: #cacaca;
        }
      }

      .report-attachment-row {
        ion-img {
          height: 200px;
          object-fit: contain;
        }
      }
    }
  }
}
