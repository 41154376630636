.state-tag {
  display: inline-block;
  padding: 6px 12px;
  border-radius: 12px;
  background-color: #dbdbdb;
  color: white;
  text-align: center;
  font-size: 14px;

  &.rescheduling {
    background-color: #fddd26;
    color: #121212;
  }

  // Report States

  &.pending_review,
  &.pending_assign {
    background-color: #5b8ac3;
  }

  &.assign_team {
    background-color: #2d71c3;
  }

  &.pending_approval {
    background-color: #f5b300;
  }

  &.rejected {
    background-color: #cf021c;
  }

  // Inquiry States

  &.draft {
    background-color: #dbdbdb;
  }

  &.pending_payment,
  &.pending_start {
    background-color: #f1701b;
  }

  &.pending_provider,
  &.pending_seeker {
    background-color: #1b4171;
  }

  &.completed {
    background-color: #21ba45;
  }

  &.pending_completion {
    background-color: #18abcc;
  }

  &.pending_reviews {
    background-color: #f1701b;
  }

  &.cancelled,
  &.rejected {
    background-color: #f44336;
  }

  &.assign-team {
    background-color: #2d71c3;
  }

  // Payment States

  &.paid,
  &.captured,
  &.approved,
  &.active {
    background-color: #21ba45;
  }

  &.expected {
    background-color: #2d95f4;
  }

  &.pending_approval {
    background-color: #ffcb2b;
  }

  &.suspened,
  &.pending {
    background-color: #f1701b;
  }

  &.failed,
  &.banned {
    background-color: #f44336;
  }

  &.voided {
    background-color: #dbdbdb;
  }

  // Subscription States

  &.pending_renewal {
    background-color: #fbd63b;
  }
}

.state-tag-round {
  padding: 5px 14px;
  border-radius: 20px;
  display: inline-block;
  background-color: #dbdbdb;
  color: white;
  text-align: center;

  &.rescheduling {
    background-color: #fddd26;
    color: #121212;
  }

  // Inquiry States

  &.pending_review {
    background-color: #5b8ac3;
  }

  &.draft {
    background-color: #dbdbdb;
  }

  &.pending_payment,
  &.pending_start {
    background-color: #f1701b;
  }

  &.pending_provider,
  &.pending_seeker {
    background-color: #1b4171;
  }

  &.completed {
    background-color: #21ba45;
  }

  &.pending_completion {
    background-color: #18abcc;
  }

  &.pending_reviews {
    background-color: #f1701b;
  }

  &.cancelled,
  &.rejected {
    background-color: #f44336;
  }

  // Payment States

  &.paid,
  &.captured,
  &.approved,
  &.active {
    background-color: #21ba45;
  }

  &.expected {
    background-color: #2d95f4;
  }

  &.pending_approval {
    background-color: #ffcb2b;
  }

  &.suspened,
  &.pending {
    background-color: #f1701b;
  }

  &.failed,
  &.banned {
    background-color: #f44336;
  }

  &.voided {
    background-color: #dbdbdb;
  }

  // Subscription States

  &.pending_renewal {
    background-color: #fbd63b;
  }
}
