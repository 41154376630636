.reports-page-label {
    font-size: 24px;
    font-weight: bold;
}

.reports-header-content {
    background-color: #F7FBFF;
    padding-left: 4% !important;
    padding-right: 32% !important;
}

.reports-page-content {
    padding-left: 4% !important;
    padding-right: 4% !important;
    min-width: 1400px;
}

.reports-search-col {
    display: flex
}

.reports-search-bar {
    width: 66%;
}

.reports-search-button {
    align-self: center;
}

.reports-list-content {
    min-height: 64vh;
}

.reports-list {
    min-width: 1400px;
}

.reports-list > ion-row {
    border-width: 1px;
    border-color: #EEEEEE;
    border-style: solid;
}

.reports-list-headers{
    background-color: #AED1FC;
}

.reports-list-headers > ion-col {
    margin: 8px 8px;
    color: #1B4171;
    font-weight: bold;
}

.reports-list > ion-row > ion-col {
    margin-left: 8px;
    margin-right: 8px;
}

.report-rows > ion-col{
    display: flex
}

.report-rows > ion-col ion-text {
    align-self: center;
}

.report-rows > ion-col > ion-label {
    display: flex;
    align-self: center;
}

.report-rows > ion-col > ion-label > ion-icon {
    font-size: 24px;
}
.reports-page-job-link {
    margin-left: 4px;
    text-decoration-line: underline;
}

.item {
    display: flex;
    justify-items: center;
}

.item-text-black {
    color: #000000;
}

.item-text {
    margin: 8px;
    color: #1b4171;
}

.ion-icon {
    font-size: 20px;
    color: #1b4171
}